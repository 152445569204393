<template>
  <v-container >
    <v-row>
      <v-col cols="4" md="2" class="mobile">
        <v-card outlined height="100%">
          <v-card-title primary-title> Meta Geral </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <apexchart
                  type="radialBar"
                  v-if="radial"
                  :options="radial.chartOptions"
                  :series="radial.series"
                ></apexchart>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                ><h1 v-if="metaGeral" class="text-center">
                  {{ metaGeral.venda_geral }} / {{ metaGeral.meta_geral }}
                </h1></v-col
              >
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4" md="5" class="mobile">
        <v-card outlined>
          <v-card-title primary-title> Tecnologias </v-card-title>
          <v-card-text>
            <apexchart
              type="pie"
              data
              :options="graph"
              :series="series"
              
            ></apexchart>
          </v-card-text>
        </v-card>
      </v-col>
            <v-col cols="4" md="5" class="mobile">
        <v-card outlined>
          <v-card-title primary-title> Vendas por Time </v-card-title>
          <v-card-text>
            <apexchart
              type="donut"
              data
              :options="teams.graph"
              :series="teams.series"
              
            ></apexchart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
            <v-col cols="9" md="9" class="mobile">
        <v-card outlined>
          <v-card-title primary-title> Vendas por Dia </v-card-title>
          <v-card-text>
            <apexchart
              v-if="loaded"
              type="bar"
              :options="options"
              :series="dados"
            ></apexchart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card>
      <v-data-table
        v-if="sales"
        :headers="headers"
        :items="sales"
        :items-per-page="5"
        class="elevation-1"
        :key="sales.id"
      >
       <template v-slot:item.taxa="{ item }">
          {{ getTaxa(item.taxa) }}
        </template>
        <template v-slot:item.ref_v_stage_contrato="{ item }">
          <v-chip :color="getColor(item.ref_v_stage_contrato)" dark>
            <v-icon left> {{ getIcon(item.ref_v_stage_contrato) }}</v-icon>
            {{ item.ref_v_stage_contrato }}
          </v-chip>
        </template>

        <template v-slot:item.ref_data_venda="{ item }">
          {{ item.ref_data_venda | formatDate }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { formatDate } from "../mixins";

import dayjs from "dayjs";
export default {
  name: "Dash",
  mixins: [formatDate],
  data: () => {
    return {
      sales: "",
      loaded: false,
      radial: null,
      metaGeral: null,
      teams: {
        
        graph: {
          plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
        }
      }
    }
          },
                  chart: {
          width: 380,
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        labels: []

        },
        series: []
      },
      graph: {
        chart: {
          width: 380,
          type: "pie",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        labels: []

      },
      series: [],
      options: {
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%",
          },
        },
        colors: ["#57B45E", "#F44336"],

        xaxis: {
          categories: [],
        },
      },
      dados: [
        {
          name: "Vendas Efetuadas",

          data: [],
        },
      ],
      headers: [
        { text: "Vendedor", value: "ref_nome_vendedor" },
        { text: "Tecnologia", value: "ref_tipo_valida" },
        { text: "Data da Venda", value: "ref_data_venda" },
                { text: "Instação", value: "taxa" },

                { text: "Motivo", value: "ref_evento_contrato" },

        { text: "Status", value: "ref_v_stage_contrato" },
      ],
    };
  },
  async created() {
    this.getSales();
    this.getChart();
    this.chart();
    this.getTech()
    this.getChartTeam()
  },

  methods: {
    getColor(stage) {
      if (stage === "Em Aprovação") return "primary";
      else if (stage === "Cancelado") return "error";
      else if (stage === "Aprovado") return "success";
      else return "green";
    },
        getTaxa(taxa) {
      if (taxa) return "Instalação Paga";
      else return "Instalação Gratuita";
    },
    getIcon(stage) {
      if (stage === "Em Aprovação") return "mdi-circle-slice-6";
      else if (stage === "Cancelado") return "mdi-alert-circle-outline";
      else if (stage === "Aprovado") return "mdi-check-circle";
      else return "green";
    },
     async getTech(){
      const { data } = await this.$http.get("saleschart?chart=tech");

      data.forEach((d) => {
        this.graph.labels.push(d.ref_tipo_valida);

        this.series.push(
          d.count
        );
      });
    },
        async getChartTeam() {
      const { data } = await this.$http.get("saleschart?chart=teams");

      data.forEach((d) => {
        this.teams.graph.labels.push(d.nome);

        this.teams.series.push(
          d.count
        );
      });
      this.loaded = true;
    },
    async getChart() {
      const { data } = await this.$http.get("saleschart?chart=sales");

      data.forEach((d) => {
        this.dados[0].data.push(d.count);

        this.options.xaxis.categories.push(
          dayjs(d.ref_data_venda).format("DD/MM/YYYY")
        );
      });
      this.loaded = true;
    },

    async chart() {
      const { data } = await this.$http.get("saleschart?chart=goalteams");
      this.metaGeral = data;
      this.radial = {
        series: [((this.metaGeral.venda_geral / this.metaGeral.meta_geral) * 100).toFixed(2)],
        chartOptions: {
          chart: {
            type: "radialBar",
          },

          plotOptions: {
            radialBar: {
              hollow: {
                size: "70%",
              },
            },
          },
          labels: ["Total"],
        },
      };
    },
    async getSales() {
      try {
        const result = await this.$http.get("/sales");
        this.sales = result.data;
      } catch (error) {
        this.$toast.error("Error ao listar vendas!")
      }
    },
  },
};
</script>

<style>
</style>